import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "gatsby";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";

const StepOne = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return (
    <div className="onboarding-step-one">
      <div className="card-header">
        <img src="/images/common/plus_new_logo.svg" />
      </div>

      <div className="card-body row card-body-padding-mobile">
        <div className="lottie">
        {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
        </div>
        <div className="body-text">
          <span className="welcome">Welcome</span>, I’m Sofie Ai. <br />I will
          be your guide!
        </div>
        <Link to="/onboarding/user-details">
          <button>Continue</button>
        </Link>
      </div>
      <div className="card-footr">
        Already a member? <a href="/login">Login In</a>
      </div>
    </div>
  );
};

export default StepOne;
