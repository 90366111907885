import React from "react";
import OnboardingStepOne from "../../components/cards/onboardingCard/stepOne";
import "./style.scss";
import { LiveChatWidget } from '@livechat/widget-react'
import StepThree from "../../components/cards/onboardingCard/stepThree";

const StepOne = (props) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // console.log(props)
  const query = new URLSearchParams(props.location.search);
  const userEmail = query.get('usr_email')

  return (
    <div className="bg bg-img no-bg">
      <div className="mid-card">
        <StepThree 
        userEmail={userEmail}
        />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default StepOne;
